import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const InstagramRounded: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 35 35">
            <path fillRule="evenodd" clipRule="evenodd" d="M22.5939 8.25512H12.4068C10.1132 8.25512 8.24683 10.1183 8.24683 12.4064V22.5193C8.24683 24.8081 10.114 26.6705 12.4068 26.6705H22.5939C24.8876 26.6705 26.7539 24.8073 26.7539 22.5193V12.4064C26.7539 10.1176 24.8868 8.25512 22.5939 8.25512ZM17.5272 23.5305H17.4735C14.1665 23.5305 11.4464 20.8161 11.4464 17.4624C11.4464 14.1088 14.1665 11.3944 17.4735 11.3944H17.5272C20.8342 11.3944 23.6081 14.1624 23.6081 17.4624C23.6081 20.7625 20.8342 23.5305 17.5272 23.5305ZM23.5007 12.7792C22.7543 12.7792 22.2204 12.1936 22.2204 11.5016C22.2204 10.8096 22.7535 10.224 23.5007 10.224C24.1941 10.224 24.781 10.8096 24.781 11.5016C24.781 12.1936 24.1941 12.7792 23.5007 12.7792Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.5273 13.4712H17.4736C15.2866 13.4712 13.4731 15.2808 13.4731 17.4632C13.4731 19.6456 15.2866 21.5081 17.4736 21.5081H17.5273C19.7143 21.5081 21.5277 19.6985 21.5277 17.4632C21.5277 15.228 19.7143 13.4712 17.5273 13.4712Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.5 0C7.83511 0 0 7.81865 0 17.4632C0 27.1078 7.83511 34.9265 17.5 34.9265C27.1649 34.9265 35 27.1078 35 17.4632C35 7.81865 27.1649 0 17.5 0ZM28.6748 22.5729C28.6748 25.8729 25.9546 28.5873 22.6476 28.5873H12.3531C9.04614 28.5873 6.326 25.8729 6.326 22.5729V12.3536C6.326 9.05354 9.04614 6.33911 12.3531 6.33911H22.6476C25.9546 6.33911 28.6748 9.05354 28.6748 12.3536V22.5729Z" />
        </SvgIcon>
    );
};
