import { SxProps, Theme } from '@mui/material';
import BgFooter from '../svg/bgFooter.svg';

export const BoxFooterSx: SxProps<Theme> = {
    backgroundImage: `url(${BgFooter})`,
    backgroundPosition: "top center",
    backgroundSize: "cover", 
    height: "auto",
    overflow: "hidden",
    mt: "10%",
    zIndex: 200
};

export const BoxCintasSx: SxProps<Theme> = {
    width: "100%",
    overflow: "hidden",
};
