import { CSSProperties } from "react";

export const Cinta1Style: CSSProperties = {
    width: "900px",
    minWidth: "100vw",
    transform: "translate(0, -250px)",
};

export const Cinta2Style: CSSProperties = {
    width: "900px",
    minWidth: "100vw",
    transform: "translate(0, -5px)",
};