import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TiktokRounded: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 35 35">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 0C7.83512 0 0 7.83511 0 17.5C0 27.1649 7.83512 35 17.5 35C27.1649 35 35 27.1649 35 17.5C35 7.83511 27.1649 0 17.5 0ZM27.849 15.9636C25.8932 16.11 23.9863 15.4749 22.52 14.1549V22.1737C22.52 26.0854 19.2932 29.2632 15.3324 29.2632C13.2793 29.2632 11.4207 28.3829 10.1007 27.0139C8.92707 25.7429 8.19391 24.0315 8.19391 22.1737C8.19391 18.311 11.2744 15.1332 15.1854 15.0351H15.3317C15.6741 15.0351 16.0158 15.0842 16.3092 15.1332V19.0449C16.0158 18.9469 15.6733 18.8986 15.3317 18.8986C13.5229 18.8986 12.0558 20.3657 12.0558 22.1744C12.0558 22.8586 12.2512 23.5435 12.6426 24.0813C13.2785 24.9125 14.2561 25.4503 15.3317 25.4503C17.1895 25.4503 18.6565 23.9832 18.6565 22.1744V6.77352H22.5192C22.4702 8.09351 23.008 9.46253 23.9373 10.4891C24.7195 11.3694 25.6488 11.9562 26.7244 12.1025C27.0668 12.2006 27.4575 12.2006 27.849 12.1025V15.9652V15.9636Z" />
        </SvgIcon>
    );
};
