import React from 'react';
import { Button } from '@mui/material';
import { FacebookRounded } from '@mui/icons-material';
import { InstagramRounded, TiktokRounded } from '../customIcons';

const icons = {
    FacebookRounded,
    InstagramRounded,
    TiktokRounded,
};

interface SocialNetworkComponentProps {
    child: React.ReactNode;
    name: keyof typeof icons;
    url?: string;
}

export const SocialNetworkComponent: React.FC<SocialNetworkComponentProps> = ({ child, name, url }) => {
    const Icon = icons[name];

    return (
        <Button
            component="a"
            href={url}
            target={url ? "_blank" : undefined}
            rel={url ? "noopener noreferrer" : undefined}
            color="secondary"
            variant="text"
            startIcon={<Icon />}
            sx={{
                p: 3,
                textTransform: 'none',
                color: '#FFFFFF',
                fontWeight: 800,
                fontSize: 20,
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#E6FF00',
                },
                textDecoration: 'none',
            }}
        >
            {child}
        </Button>
    );
};

export default SocialNetworkComponent;
