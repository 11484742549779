import React, { useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import BackgroundCity from '../img/bg.png';
import Logo from '../svg/logo.svg';
import Drone from '../img/drone.png';
import { BoxBackgroundCitySx, BoxComingSoonSx, BoxContentSx, BoxDroneSx, BoxLogoSx, mainBox } from './SxProps';
import { BackgroundCityStyle } from './CSSProperties';
import { FooterComingSoon } from '../FooterComingSoon/FooterComingSoon';

export const ComingSoon = () => {
  const backgroundCityRef = useRef<HTMLDivElement>(null);
  const comingSoonRef = useRef<HTMLDivElement>(null);
  const droneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let animationFrameId: number;

    const handleMouseMove = (event: MouseEvent) => {
      const { clientX, clientY } = event;
      const { innerWidth, innerHeight } = window;

      const moveX = (clientX - innerWidth / 2) / innerWidth;
      const moveY = (clientY - innerHeight / 2) / innerHeight;

      animationFrameId = requestAnimationFrame(() => {
        if (backgroundCityRef.current) {
          backgroundCityRef.current.style.transform = `translate(${moveX * -15}px, ${moveY * -15}px)`;
        }
        if (comingSoonRef.current) {
          comingSoonRef.current.style.transform = `translate(${moveX * 15}px, ${moveY * 15}px) translateY(-130px)`;
        }
        if (droneRef.current) {
          droneRef.current.style.transform = `translate(${moveX * 100}px, ${moveY * 100}px)`;
        }
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      cancelAnimationFrame(animationFrameId);  // Limpiar el frame de animación
    };
  }, []);

  return (
    <Box sx={mainBox}>
      <Box sx={BoxBackgroundCitySx} ref={backgroundCityRef}>
        <img src={BackgroundCity} alt="Edificios Ciudad" style={BackgroundCityStyle} />
      </Box>
      <Box sx={BoxContentSx}>
        <Box sx={BoxLogoSx}>
          <img src={Logo} alt="Logotipo Sky Drone" />
        </Box>
        <Box sx={BoxDroneSx} ref={droneRef}>
          <img src={Drone} alt="Drone" width={572} />
        </Box>
        <Box sx={BoxComingSoonSx} ref={comingSoonRef}>COMING SOON</Box>
        <FooterComingSoon />
      </Box>
    </Box>
  );
};
