import { SxProps, Theme } from "@mui/material";

export const mainBox: SxProps<Theme> = {
  maxWidth: "100vw",
  width: "100%",
  height: "auto",
  overflow: "hidden",  // Asegura que no haya scroll horizontal
  zIndex: 0,
  position: "relative",
  textAlign: "center",
};

export const BoxBackgroundCitySx: SxProps<Theme> = {
  position: "fixed",
  width: "100%",
  height: "100%",  // Asegura que cubra toda la vista
  top: 0,  // Asegura que esté en la parte superior
  left: 0,  // Asegura que esté en la parte izquierda
  zIndex: 1,
};

export const BoxContentSx: SxProps<Theme> = {
  position: "relative",  // Cambiado a "relative" para mantener el contenido centrado y permitir el scroll vertical
  width: "100%",
  height: "100%",  // Ajustado para cubrir toda la vista
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",  // Centra el contenido verticalmente
  alignItems: "center",  // Centra el contenido horizontalmente
  zIndex: 2,
  mt:"20vh"
};

export const BoxLogoSx: SxProps<Theme> = {
  position: "relative",
  maxWidth: "100%",
  zIndex: 100,
};

export const BoxDroneSx: SxProps<Theme> = {
  position: "relative",
  pt: "52px",
  zIndex: 99,
  transform: "translateY(20px)",  // Ajuste de la posición inicial del drone
};

export const BoxComingSoonSx: SxProps<Theme> = {
  position: "absolute",
  width: "100%",
  fontSize: 160,
  fontWeight: 800,
  opacity: "80%",
  zIndex: 98,
  mt:"-25vh"
};
