import { Box, Grid } from '@mui/material'
import { BoxCintasSx, BoxFooterSx } from './SxProps'
import Cinta1 from '../img/cinta1.png'
import Cinta2 from '../img/cinta2.png'
import { Cinta1Style, Cinta2Style } from './CSSProperties'
import { SocialNetworkComponent } from '../SocialNetworkComponent/SocialNetworkComponent'

export const FooterComingSoon = () => {
    return (
        <Box sx={BoxFooterSx}>
            <Box sx={BoxCintasSx}>
                <img src={Cinta2} alt="Cinta de precaución" style={Cinta2Style} />
                <img src={Cinta1} alt="Cinta de precaución" style={Cinta1Style} />
            </Box>
            <Grid container sx={{ color: "#ffff", transform: "translate(0, -70%)", px: "20%" }}>
                <Grid item sm={4}>
                    <SocialNetworkComponent name="FacebookRounded" child="skycleanersde" url="https://www.facebook.com/SKYCLEANERSDE" />
                </Grid>
                <Grid item sm={4}>
                    <SocialNetworkComponent name="InstagramRounded" child="skycleanersde" url="https://www.instagram.com/skycleanersde/" />
                </Grid>
                <Grid item sm={4}>
                    <SocialNetworkComponent name="TiktokRounded" child="skycleanersde" url="https://www.tiktok.com/@skycleanersde" />
                </Grid>
            </Grid>
        </Box>
    )
}
